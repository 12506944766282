import React, { useState } from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, SectionStatic, BreadCrumb } from "../components/Section"
import { BulletIcon } from "../components/Icons"
import TopPageHeader from "../components/TopPageHeader"
import ColorCard from "../components/ColorCard"
import RequestQuote from "../components/RequestQuote"
import BreakpointUp from "../components/Media/BreakpointUp"
import QuotePopup from "../components/QuotePopup"

const ColorCodeGrid = styled.div`
  margin: 50px 0;
  .list-color {
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: 30px;
    ${BreakpointUp.md`         
      margin-bottom:50px;
    `}
    .color-item {
      padding-right: 15px;
      padding-left: 15px;
      margin-bottom: 20px;
      ${BreakpointUp.md`         
        margin-bottom:40px;        
      `}
    }
    .card {
      border: none;
      padding: 0;
    }
    .color {
      background-color: #fff;
      border: 1px solid #e3e3e3;
      padding: 0 10px;
      color: #211f28;
      font-weight: 600;
      max-width: 122px;
      width: 100%;
      min-height: 20px;
      line-height: 20px;
      margin: 0 auto;
      bottom: 10px;
      position: relative;
      font-size: 15px;
      ${BreakpointUp.sm`   
        font-size: 16px;
      `}
      &:before,  &:after {
        content: "";
        height: 0;
        width: 0;
        position: absolute;
      }
      &:after {
        border-top: 14px solid #6a6a6a;
        border-left: 14px solid transparent;
        bottom: 0;
        left: -14px;
      }
      &:before {
        border-top: 14px solid #6a6a6a;
        border-right: 14px solid transparent;
        bottom: 0;
        right: -14px;
      }
    }
  }
  + .card {
    @media (min-width: 1600px) {
      margin: 0 -85px 90px -85px;
    }
    &:before,
    &:after {
      display: none;
      @media (min-width: 1600px) {
        display: block;
      }
    }
  }
`
function ColorOptionPage({ data, intl }) {
  const pageData = data.contentfulColorPage
  const [isVisibleQuote, setQuoteVisible] = useState(false)
  if (typeof window !== `undefined`) {
    if (isVisibleQuote === true) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
  }
  return (
    <Layout>
      <SEO title={pageData.metaTitle} description={pageData.metaDescription} />
      <BreadCrumb>
        <div className="container">
          <Link to="/">
            {intl.formatMessage({ id: "Home" })} <BulletIcon />
          </Link>
          <Link to="/">
            {intl.formatMessage({ id: "Buyer's Guide" })} <BulletIcon />
          </Link>
          <span>{pageData.pageName}</span>
        </div>
      </BreadCrumb>
      <TopPageHeader
        topHeaderTitle={pageData.heroTitle}
        topHeaderDesc={pageData.heroDescription}
      />
      <Section
        pt="0"
        pb="90px"
        xpt="0"
        xpb="60px"
        bgColor="transparent"
        top="-100px"
        xtop="-50px"
      >
        <div className="container">
          <SectionStatic>
            <ColorCodeGrid>
              <ColorCard data={pageData.colors} />
            </ColorCodeGrid>
            <RequestQuote
              intl={intl}
              openQuoteModal={() => setQuoteVisible(true)}
            />
          </SectionStatic>
        </div>
      </Section>
      <QuotePopup
        isVisible={isVisibleQuote}
        onClose={() => setQuoteVisible(false)}
        intl={intl}
      />
    </Layout>
  )
}

export default injectIntl(ColorOptionPage)

export const pageQuery = graphql`
  query ColorOptionPageQuery($locale: String) {
    contentfulColorPage(node_locale: { eq: $locale }) {
      metaDescription
      pageName
      metaTitle
      node_locale
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      colors {
        title
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
        description
      }
    }
  }
`
